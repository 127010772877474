const { UNIT, UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD } = require('../../constants');

function getSoilTempParams(trtChecked = false) {
  const params = {
    InitTrtChecked: {
      key: 'InitTrtChecked',
      type: 'boolean',
      default: false
    },
    InitUndisturbedSoilTemperatureCalculationMethod: {
        key: 'InitUndisturbedSoilTemperatureCalculationMethod',
        type: 'string',
        values: Object.values(UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD),
        default: UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD.WEATHER_FILE
    },
    InitSoilTemperature10m: {
      key: 'InitSoilTemperature10m',
      type: 'number',
      required: true,
      default: 12,
      unit: UNIT.CELSIUS_DEGREE
    },
    InitSoilTemperatureMean: {
      key: 'InitSoilTemperatureMean',
      type: 'number',
      required: true,
      min: 0,
      default: 0,
      unit: UNIT.CELSIUS_DEGREE
    },
    InitTrtProbeDepth: {
      key: 'InitTrtProbeDepth',
      type: 'number',
      required: true,
      min: 0,
      max: 200,
      default: 0,
      unit: UNIT.METER
    }
  };
  if (trtChecked) {
    params.InitSoilTemperatureMean.exclusiveMin = 0;
    params.InitTrtProbeDepth.exclusiveMin = 0;
    params.InitTrtProbeDepth.max = 200;
  } else {
    params.InitSoilTemperature10m.min = 8;
    params.InitSoilTemperature10m.max = 30;
  }
  return params;
}

const SOIL = Object.freeze({
  InitSoilThermalConductivity: {
    key: 'InitSoilThermalConductivity',
    type: 'number',
    required: true,
    min: 1.3,
    max: 6,
    default: 1.7,
    unit: UNIT.WATT_PER_METER_PER_KELVIN
  },
  InitSoilVolumeHeatCapacity: {
    key: 'InitSoilVolumeHeatCapacity',
    type: 'number',
    required: true,
    min: 500000,
    max: 4000000,
    default: 2297000,
    unit: UNIT.JOULE_PER_CUBIC_METER_PER_KELVIN
  }
});

const BTES = Object.freeze({
  InitBtesTemperatureMin: {
    key: 'InitBtesTemperatureMin',
    type: 'number',
    required: true,
    min: -7,
    max: 8,
    default: -3,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitBtesTemperatureMax: {
    key: 'InitBtesTemperatureMax',
    type: 'number',
    required: true,
    min: 20,
    max: 50,
    default: 40,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitBtesTemperatureDelta: {
    key: 'InitBtesTemperatureDelta',
    type: 'number',
    required: true,
    min: 0,
    default: 4,
    unit: UNIT.CELSIUS_DEGREE
  },
  YearsT2Final: {
    key: 'YearsT2Final',
    type: 'number',
    required: true,
    min: 0,
    default: 15,
    unit: UNIT.YEAR
  },
  InitBtesMaxWidth: {
    key: 'InitBtesMaxWidth',
    type: 'number',
    required: true,
    min: 0,
    max: 1000,
    default: 0,
    unit: UNIT.METER
  },
  InitBtesProbeResistance: {
    key: 'InitBtesProbeResistance',
    type: 'number',
    required: true,
    min: 0.03,
    max: 1,
    default: 0.11,
    unit: UNIT.WATT_PER_METER_PER_KELVIN
  },
  InitBtesProbeInternalDiameter: {
    key: 'InitBtesProbeInternalDiameter',
    type: 'number',
    required: true,
    min: 0,
    max: 0.05,
    default: 0.026,
    unit: UNIT.METER
  }
});

const GEOLOGY_PARAMS = [
  ...Object.values(getSoilTempParams()),
  ...Object.values(SOIL),
  ...Object.values(BTES)
];

module.exports = {
  SOIL,
  BTES,
  GEOLOGY_PARAMS,
  getSoilTempParams
};
