import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import PopupContext from '../../../../contexts/PopupContext';

const InfosCAPEXwithProgressiveDiscount = () => {
  const { i18n } = useLingui();
  const { openInfoModal } = useContext(PopupContext);

  return (
    <FontAwesomeIcon
      icon='circle-question'
      className='modal-info-icon'
      onClick={() =>
        openInfoModal(
          i18n._('cost.modal.capexWithProgressiveDiscount.title'),
          <div>
            {i18n._('cost.modal.capexWithProgressiveDiscount.content')}
            <b>{i18n._('cost.modal.capexWithProgressiveDiscount.footer')}</b>
          </div>
        )
      }
    />
  );
};

export default InfosCAPEXwithProgressiveDiscount;
