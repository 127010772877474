import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getBtesParams } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import EditTable from '../../../../components/EditTable/EditTable';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import PopupContext from '../../../../contexts/PopupContext';
import { INPUT_TYPE } from '../../../../constants';
import InfosCAPEXwithProgressiveDiscount from '../modal/InfosCAPEXwithProgressiveDiscount';

const BtesBloc = ({
  form,
  projectLocation,
  onInputChange,
  onTableValueChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  const { openInfoModal } = useContext(PopupContext);

  //#region [render]
  const btesParams = getBtesParams(
    projectLocation.country,
    projectLocation.zipCode.substring(0, 2)
  );
  return (
    <Bloc title={i18n._('cost.btes')} level={2}>
      <Row style={{ marginBottom: '-5px' }}>
        <Col>
          <span className='param-input-label'>
            {i18n._('cost.InitBtesCostA')}
          </span>
        </Col>
      </Row>
      <Row style={{ marginTop: '-10px', marginBottom: '-15px' }}>
        <Col>
          <p className='param-input-small-text param-input-top-text'>
            {i18n._('cost.topInitBtesCostA')}
          </p>
        </Col>
        <Col className='ps-3'>
          <span className='param-input-label'>
            {i18n._('cost.InitBtesCostB')}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            value={form.InitBtesCostA}
            param={btesParams.InitBtesCostA}
            onChange={(value) => onInputChange('InitBtesCostA', value)}
            unit
            bottomText={i18n._('cost.InitBtesCostA.default', {
              value: btesParams.InitBtesCostA.default,
              unit: i18n._(`unit.${btesParams.InitBtesCostA.unit}`),
              department: btesParams.InitBtesCostA.department
            })}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col className='ps-3'>
          <ParamInput
            value={form.InitBtesCostB}
            param={btesParams.InitBtesCostB}
            onChange={(value) => onInputChange('InitBtesCostB', value)}
            unit
            bottomText={i18n._('cost.InitBtesCostB.default', {
              value: btesParams.InitBtesCostB.default,
              unit: i18n._(`unit.${btesParams.InitBtesCostB.unit}`),
              department: btesParams.InitBtesCostB.department
            })}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <div>
        <span className='param-input-label'>
          {i18n._('cost.InitBtesDiscountLabel')}
        </span>

        <InfosCAPEXwithProgressiveDiscount />

        <EditTable
          titles={[
            i18n._('cost.InitBtesDiscountDictSlices'),
            i18n._('cost.InitBtesDiscountDict')
          ]}
          values={[form.InitBtesDiscountDict]}
          params={[btesParams.InitBtesDiscountDict]}
          onChange={onTableValueChange}
        />
      </div>
    </Bloc>
  );
  //#endregion
};

export default BtesBloc;
