import axios from '../conf/axios.conf';

//#region [GET]
export const fetchConfigSst = async (configSstId) => {
  try {
    const response = await axios.get(`/configsst/${configSstId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchHpsCatalog = async (configSstId) => {
  try {
    const response = await axios.get(`/configsst/${configSstId}/hpsCatalog`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [POST]
export const fetchRegimesDependsOnOutsideTemp = async (configSstId, body) => {
  try {
    const response = await axios.post(
      `/configsst/${configSstId}/pumps/outsideTemp/regimes`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchRegimesDependsOnSrcTemp = async (configSstId, body) => {
  try {
    const response = await axios.post(
      `/configsst/${configSstId}/pumps/srcTemp/regimes`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchRegimesDependsOnSrcTempDelta = async (configSstId, body) => {
  try {
    const response = await axios.post(
      `/configsst/${configSstId}/pumps/srcTempDelta/regimes`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchGeostorageHeadLossData = async (configSstId, body) => {
  try {
    const response = await axios.post(
      `/configsst/${configSstId}/geostorage/headLoss`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchExchangerPipingHeadLossBody = async (configSstId, body) => {
  try {
    const response = await axios.post(
      `/configsst/${configSstId}/exchangerHeadLoss/pipingBody`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchExchangerPipingHeadLossData = async (configSstId, body) => {
  try {
    const response = await axios.post(
      `/configsst/${configSstId}/exchangerHeadLoss/piping`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchExchangerCollectorsHeadLossData = async (
  configSstId,
  body
) => {
  try {
    const response = await axios.post(
      `/configsst/${configSstId}/exchangerHeadLoss/collectors`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchExchangerCollectorsHeadLossBody = async (
  configSstId,
  body
) => {
  try {
    const response = await axios.post(
      `/configsst/${configSstId}/exchangerHeadLoss/collectorsBody`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchPumpOperatingCurves = async (configSstId, exchangers) => {
  try {
    const response = await axios.post(
      `/configsst/${configSstId}/exchangerHeadLoss/pumpOperatingCurves`,
      exchangers
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [PUT]
export const updateConfigSst = async (configSst) => {
  try {
    const response = await axios.put(
      `/configsst/${configSst.ConfigSstID}`,
      configSst
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateServicesSettings = async (isModular, configSst, modules) => {
  try {
    const body = { isModular, configSst, modules };
    const response = await axios.put(
      `/configsst/${configSst.ConfigSstID}/services/settings`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateServicesHydraulicConfig = async (
  isModular,
  configSst,
  modules
) => {
  try {
    const body = { isModular, configSst, modules };
    const response = await axios.put(
      `/configsst/${configSst.ConfigSstID}/services/hydraulic`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateHpsSettings = async (isModular, configSst, modules) => {
  try {
    const body = { isModular, configSst, modules };
    const response = await axios.put(
      `/configsst/${configSst.ConfigSstID}/hps/settings`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateSelectedHps = async (isModular, configSst, modules) => {
  try {
    const body = { isModular, configSst, modules };
    const response = await axios.put(
      `/configsst/${configSst.ConfigSstID}/hps/selected`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateHpsHydraulicConfig = async (isModular, configSst) => {
  try {
    const body = { isModular, configSst };
    const response = await axios.put(
      `/configsst/${configSst.ConfigSstID}/hps/hydraulic`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updatePumpsRegimes = async (configSst, isModular) => {
  try {
    const body = { configSst, isModular };
    const response = await axios.put(
      `/configsst/${configSst.ConfigSstID}/pumps/regimes`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateHeatingPlant = async (configSst, isModular) => {
  try {
    const body = { configSst, isModular };
    const response = await axios.put(
      `/configsst/${configSst.ConfigSstID}/pumps/heatingPlant`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateGeostorageSettings = async (
  configSst,
  isModular,
  modules
) => {
  try {
    const body = { configSst, isModular, modules };
    const response = await axios.put(
      `/configsst/${configSst.ConfigSstID}/geostorage/settings`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion
