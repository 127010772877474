import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import { deleteConfig, fetchConfig } from '../../../../api/config.api';
import { createConfig, fetchConfigs } from '../../../../api/project.api';
import PopupContext from '../../../../contexts/PopupContext';
import reactSelectStyles from '../../../../styles/react-select.style';
import { isPageAccessible } from '../../../../utils/config.utils';
import { isArrNullOrEmpty } from '../../../../utils/data.utils';
import { formatDateAndHour } from '../../../../utils/date.utils';
import './Header.css';

const Header = ({ savedConfig }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const location = useLocation();
  const { companyId, projectId, configId } = useParams();
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  const { openErrorToast, openConfirmModal } = useContext(PopupContext);
  //#endregion

  //#region [states]
  // todo remplacer configs par options
  const [configs, setConfigs] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        if (!projectId) return;
        const configs = await fetchConfigs(projectId);
        setConfigs(() => configs);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [projectId, savedConfig?.Tags]);

  useEffect(() => {
    if (isArrNullOrEmpty(configs) || configId) return;
    navigateToGeneral(configs[0].ConfigID);
  }, [configs, configId]);

  useEffect(() => {
    if (!savedConfig || isArrNullOrEmpty(configs)) return;
    setConfigs(() => {
      const index = configs.findIndex(
        (config) => config.ConfigID === savedConfig.ConfigID
      );
      if (index === -1 || configs[index].Name === savedConfig.Name) {
        return configs;
      }
      configs[index].Name = savedConfig.Name;
      configs[index].Tags = savedConfig.Tags;
      return [].concat(configs);
    });
  }, [savedConfig?.Name]);
  //#endregion

  //#region [memos]
  // todo supprimer, remplacer configs par options
  const options = useMemo(() => {
    if (isArrNullOrEmpty(configs)) return [];
    return configs.map((config) => {
      const { ConfigID, Name, created_at, Tags } = config;
      const date = formatDateAndHour(i18n, created_at);
      const tags = Tags;
      return {
        value: ConfigID,
        label: !Name
          ? i18n._('configWithoutName', { date })
          : i18n._('configWithName', { date, name: Name }),
        tags
      };
    });
  }, [configs]);
  //#endregion

  //#region [methods]
  const navigateToGeneral = (id, state) => {
    const url = `/company/${companyId}/project/${projectId}/configs/${id}/general`;
    navigate(url, state);
  };

  const handleNewConfigClick = async () => {
    try {
      const { configId } = await createConfig(projectId);
      const newConfig = { ConfigID: configId, created_at: new Date() };
      setConfigs(() => [newConfig].concat(configs));
      navigateToGeneral(configId);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleSelectChange = async (evt) => {
    try {
      const pathnames = location.pathname.split('/');
      const configIdIndex = pathnames.findIndex(
        (pathname) => pathname === configId
      );
      pathnames.splice(0, configIdIndex + 1);
      const currentPage = pathnames.join('/');
      const newConfig = await fetchConfig(evt.value);
      const isAccessible = isPageAccessible(newConfig.ConfigsSst[0])[
        currentPage
      ];
      isAccessible
        ? navigate(
            `/company/${companyId}/project/${projectId}/configs/${evt.value}/${currentPage}`,
            { state: { config: newConfig } }
          )
        : navigateToGeneral(evt.value);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleDeleteConfig = async () => {
    try {
      await deleteConfig(configId);
      const newConfigs = configs.filter(
        (config) => config.ConfigID !== configId
      );
      setConfigs(() => newConfigs);
      isArrNullOrEmpty(newConfigs)
        ? navigate(`/company/${companyId}/project/${projectId}/configs`, {
            state: { deletedConfig: true }
          })
        : navigateToGeneral(newConfigs[0].ConfigID, {
            state: { deletedConfig: true }
          });
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleOpenDeleteModal = () => {
    openConfirmModal(
      i18n._('configs.deleteConfig.title'),
      i18n._('configs.deleteConfig.body'),
      'danger',
      async () => await handleDeleteConfig()
    );
  };

  const handleInputChange = (_, { action, prevInputValue }) => {
    if (action === 'menu-close' && !prevInputValue) {
      setIsMenuOpen(undefined);
    }
    return prevInputValue;
  };
  //#endregion

  //#region [tags components]
  const Tag = (props) => {
    return (
      <div className='results-select-tags'>
        {props?.tags?.feasibility && (
          <Badge className='feasibility-badge'>
            {i18n._('tag.feasibility')}
          </Badge>
        )}
        {props?.tags?.project && (
          <Badge className='project-badge'>{i18n._('tag.project')}</Badge>
        )}
        {props?.tags?.grantCommitment && (
          <Badge className='grant-commitment-badge'>
            {i18n._('tag.grantCommitment')}
          </Badge>
        )}
        {props?.tags?.contractCommitment && (
          <Badge className='contract-commitment-badge'>
            {i18n._('tag.contractCommitment')}
          </Badge>
        )}
      </div>
    );
  };

  //#endregion

  //#region [select components]
  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div
          className='config-header-click-area'
          onClick={() => setIsMenuOpen(true)}
        >
          <span
            title={props.data.label}
            className='config-header-select-option-label ellipsis'
          >
            {props.data.label}
          </span>

          <Tag tags={props?.data?.tags} />

          <FontAwesomeIcon
            icon='trash-alt'
            onClick={handleOpenDeleteModal}
            className='configs-header-delete-icon'
          />
        </div>
      </components.SingleValue>
    );
  };

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div
          className='config-header-select-option'
          onClick={() => setIsMenuOpen(false)}
        >
          <span
            title={props.data.label}
            className='config-header-select-option-label ellipsis'
          >
            {props?.data?.label}
          </span>

          <Tag tags={props?.data?.tags} />
        </div>
      </components.Option>
    );
  };
  //#endregion

  //#region [render]
  return (
    <div className='configs-header'>
      {!isArrNullOrEmpty(options) && savedConfig && (
        <Select
          className='configs-header-select'
          options={options}
          value={options.find(
            (option) => option.value === savedConfig.ConfigID
          )}
          isSearchable={false}
          menuIsOpen={isMenuOpen}
          openMenuOnClick={false}
          styles={reactSelectStyles}
          components={{ SingleValue, Option }}
          onChange={handleSelectChange}
          onInputChange={handleInputChange}
        />
      )}
      <Button onClick={handleNewConfigClick}>
        <FontAwesomeIcon icon='circle-plus' className='me-2' />
        {i18n._('configs.new')}
      </Button>
    </div>
  );
  //#endregion
};

export default Header;
