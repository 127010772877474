import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../../contexts/PopupContext';
import reactSelectStyles from '../../../../../../../styles/react-select.style';
import {
  getHpsMissingNeeds,
  getTotalLevelsPerNeed
} from '../../../../../../../utils/heatpump.utils';
import './NeedsSelectCellRenderer.css';

const NeedsSelectCellRenderer = ({ data, valueFormatted, onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleOptionsChange = (options) => {
    try {
      const { services, heatpumps } = config.ConfigsSst[0].Data;
      const { list } = heatpumps;
      const index = list.findIndex((hp) => hp.id === data.id);
      list[index].needs = options.map((option) => option.value);
      // on met à jour les levels pour les régimes de la PAC
      services.needs.forEach((need) => {
        if (list[index].needs.includes(need)) {
          if (list[index].capacity[need].levels === 0) {
            list[index].capacity[need].levels = list[index].levels;
          }
        } else {
          list[index].capacity[need].levels = 0;
        }

        // on recompte les levels globaux par besoin
        heatpumps.levels[need] = getTotalLevelsPerNeed(list, need);
      });

      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const value = JSON.parse(valueFormatted);
  // les options du multi-select
  const { services, heatpumps } = config.ConfigsSst[0].Data;
  const options = services.needs.map((need) => ({
    value: need,
    label: i18n._(`need.${need}`)
  }));

  // les usages sélectionnés
  const selectedOptions = value.map((need) => ({
    value: need,
    label: i18n._(`need.${need}`)
  }));

  // erreur si aucune option sélectionnée
  let error;
  if (selectedOptions.length === 0) {
    error = i18n._('input.error.required');
  } else {
    const missingNeeds = getHpsMissingNeeds(heatpumps.list, services.needs);
    if (missingNeeds.length > 0) {
      error = i18n._('config.hps.needs.missing', {
        need: i18n._(`need.${missingNeeds[0]}`)
      });
    }
  }
  const selectId = 'multi_select_' + data.id;
  return (
    <div data-tooltip-content={error} data-tooltip-id={'error_' + selectId}>
      <Select
        id={data.id}
        isMulti
        name='needs'
        options={options}
        styles={reactSelectStyles}
        isSearchable={false}
        placeholder={i18n._('config.hps.needs.placeholder')}
        onChange={handleOptionsChange}
        value={selectedOptions}
        menuPortalTarget={document.body}
        className={`selected-hps-multi-select ${error ? 'error' : ''}`}
        classNamePrefix='multi-select'
        closeMenuOnSelect={selectedOptions.length === options.length - 1}
      />
      {error &&
        createPortal(
          <Tooltip
            id={'error_' + selectId}
            place='bottom'
            className='error-tooltip'
            arrowColor='#f04460'
            opacity={1}
          />,
          document.body
        )}
    </div>
  );
  //#endregion
};

export default NeedsSelectCellRenderer;
