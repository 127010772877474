const {
  REGIMES_HPG_BTES,
  REGIMES_HPA,
  HPA_P_HEAT,
  HPA_P_COLD,
  HPA_BTES,
  HPG_P_HEAT,
  HPG_P_COLD
} = require('../models/config/thermalProduction/heatpumps/settings.model');
const {
  DST_TEMP_DELTA,
  HP_TYPE,
  NEED,
  COLD_MANAGEMENT
} = require('../constants');

const getAeroSettingsWithNewServices = (needs, oldAeroSettings) => {
  let pHeat, pCold, btesInjection;
  if (needs.includes(NEED.HEAT) || needs.includes(NEED.DHW)) {
    pHeat = oldAeroSettings?.pHeat ?? HPA_P_HEAT.pHeat.default;
  }
  if (needs.includes(NEED.COLD)) {
    pCold = oldAeroSettings?.pCold ?? HPA_P_COLD.pCold.default;
  }
  btesInjection =
    oldAeroSettings?.btesInjection ?? HPA_BTES.btesInjection.default;
  return { pHeat, pCold, btesInjection };
};

const getGeoSettingsWithNewServices = (needs, oldGeoSettings) => {
  let pHeat, pCold;
  if (needs.includes(NEED.HEAT) || needs.includes(NEED.DHW)) {
    pHeat = oldGeoSettings?.pHeat ?? HPG_P_HEAT.pHeat.default;
  }
  if (needs.includes(NEED.COLD)) {
    pCold = oldGeoSettings?.pCold ?? HPG_P_COLD.pCold.default;
  }
  return { pHeat, pCold };
};

const getRegimesSettingsWithNewServices = (services, oldHpsRegimes) => {
  return services.needs.reduce((acc, need) => {
    // PAC aéro
    const outsideTemp =
      oldHpsRegimes[need]?.[HP_TYPE.AERO]?.outsideTemp ??
      REGIMES_HPA[need].outsideTemp.default;

    // PAC géo, côté BTES
    const srcTemp =
      oldHpsRegimes[need]?.[HP_TYPE.GEO]?.srcTemp ??
      REGIMES_HPG_BTES[need].srcTemp.default;
    const srcTempDelta =
      oldHpsRegimes[need]?.[HP_TYPE.GEO]?.srcTempDelta ??
      REGIMES_HPG_BTES[need].srcTempDelta.default;
    const srcTempOut =
      oldHpsRegimes[need]?.[HP_TYPE.GEO]?.srcTempOut ??
      (need === NEED.COLD ? srcTemp + srcTempDelta : srcTemp - srcTempDelta);

    // Distribution bâtiment
    const { temperature, temperatureBack } = services[need];

    // PAC aéro/géo, côté production
    let dstTempIn, dstTemp, dstTempDelta;
    if (need === NEED.COLD) {
      dstTempIn = temperatureBack - 1;
      dstTemp = temperature - 1;
      dstTempDelta = temperatureBack - temperature;
    } else if (need === NEED.HEAT) {
      dstTempIn = temperatureBack + 1;
      dstTemp = temperature + 1;
      dstTempDelta = temperature - temperatureBack;
    } else if (need === NEED.DHW) {
      dstTemp = temperature + 1;
      dstTempDelta = DST_TEMP_DELTA;
      dstTempIn = dstTemp - dstTempDelta;
    }

    acc[need] = {
      [HP_TYPE.AERO]: { outsideTemp },
      [HP_TYPE.GEO]: { srcTemp, srcTempOut, srcTempDelta },
      hpProd: { dstTempIn, dstTemp, dstTempDelta },
      buildingDistribution: { temperature, temperatureBack }
    };
    return acc;
  }, {});
};

const getHpsSettingsWithNewServices = (oldSettings, services) => {
  const settings = {};
  if (oldSettings.types.includes(HP_TYPE.AERO)) {
    settings[HP_TYPE.AERO] = getAeroSettingsWithNewServices(
      services.needs,
      oldSettings[HP_TYPE.AERO]
    );
  }
  if (oldSettings.types.includes(HP_TYPE.GEO)) {
    settings[HP_TYPE.GEO] = getGeoSettingsWithNewServices(
      services.needs,
      oldSettings[HP_TYPE.GEO]
    );
  }
  settings.regimes = getRegimesSettingsWithNewServices(
    services,
    oldSettings.regimes
  );
  settings.heatTransferFluid = oldSettings.heatTransferFluid;
  settings.types = oldSettings.types;
  return settings;
};

const getHpsWithNewServices = (oldHeatpumps, needs) => {
  const heatpumps = [...oldHeatpumps];
  Object.values(NEED)
    .filter((need) => !needs.includes(need))
    .forEach((need) => {
      heatpumps.forEach((hp) => {
        hp.needs = hp.needs.filter((current) => current !== need);
        Object.keys(hp.capacity).forEach(() => {
          delete hp.capacity[need];
        });
      });
    });
  return heatpumps;
};

const getLevelsWithServices = (heatpumps, needs) => {
  let updatedLevels = {};
  needs.forEach((need) => (updatedLevels[need] = heatpumps?.levels[need] ?? 0));
  return updatedLevels;
};

const hasColdAndHeatOrDhw = (hp) =>
  hp.needs.includes(NEED.COLD) &&
  (hp.needs.includes(NEED.HEAT) || hp.needs.includes(NEED.DHW));

const hasColdOnly = (hp) =>
  hp.needs.includes(NEED.COLD) &&
  !hp.needs.includes(NEED.HEAT) &&
  !hp.needs.includes(NEED.DHW);

const hasHeatOnly = (hp) =>
  !hp.needs.includes(NEED.COLD) &&
  (hp.needs.includes(NEED.HEAT) || hp.needs.includes(NEED.DHW));

const getColdManagementSelectValues = (hp, isModular) => {
  let values = [];
  if (hp.type === HP_TYPE.AERO && hasColdOnly(hp)) {
    if (isModular) {
      values.push(COLD_MANAGEMENT.ABSENT);
    } else {
      values.push(COLD_MANAGEMENT.ABSENT, COLD_MANAGEMENT.COLD_WATER_TANK);
    }
  }
  if (hp.type === HP_TYPE.AERO && hasColdAndHeatOrDhw(hp)) {
    values.push(
      COLD_MANAGEMENT.COLD_SIDE,
      COLD_MANAGEMENT.HEAT_SIDE,
      COLD_MANAGEMENT.COLD_WATER_TANK
    );
  }
  return values;
};

module.exports = {
  getHpsSettingsWithNewServices,
  getHpsWithNewServices,
  getLevelsWithServices,
  hasColdAndHeatOrDhw,
  hasColdOnly,
  hasHeatOnly,
  getColdManagementSelectValues
};
