import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment, useContext } from 'react';
import {
  EQUIPMENT_FAMILY,
  MODULES_WARNINGS,
  UNIT
} from '../../../../../../../server/constants';
import WarningBadge from '../../../../../components/WarningBadge';
import ConfigsContext from '../../../../../contexts/ConfigsContext';
import {
  getTableValue,
  isArrNullOrEmpty,
  isOutOfRange
} from '../../../../../utils/data.utils';
import SubEquipmentRow from './SubEquipmentRow';

const GeostorageRows = ({ subEquipementList, handleShowSubEquipment }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const getLowerModuleError = (lowerModule) => {
    if (!lowerModule) {
      return i18n._('config.equipmentList.warning.noModule');
    } else if (isArrNullOrEmpty(lowerModule.equipment)) {
      return i18n._('config.equipmentList.warning.noEquipment');
    } else {
      return;
    }
  };
  //#endregion

  //#region [render]
  const { services, geostorage } = config.ConfigsSst[0].Data;
  const { evaporator, condensor } = services.upperModules;
  if (!geostorage.lowerModules && !geostorage.upperModules) return;
  const isInjectionPriceComplete =
    !isOutOfRange(
      evaporator.nominalDiameter,
      MODULES_WARNINGS.UPPER_MODULES_INJECTION_ND_RANGE
    ) &&
    !isOutOfRange(
      condensor.nominalDiameter,
      MODULES_WARNINGS.UPPER_MODULES_INJECTION_ND_RANGE
    );
  return (
    <Fragment>
      {/* Modules bas captage et injection */}
      {Object.entries(geostorage.lowerModules).map(
        ([srcExchange, lowerModule], index) => {
          const lowerModuleError = getLowerModuleError(lowerModule);
          const lowerModuleRef = `${lowerModule?.name}`;
          const visibleLowerModuleEquipment = lowerModule?.equipment?.filter(
            (equipment) => equipment.family === EQUIPMENT_FAMILY.CLIMATIC
          );
          const hasSubEquipment = !isArrNullOrEmpty(
            visibleLowerModuleEquipment
          );
          const prices = lowerModule?.prices;
          return (
            geostorage.settings.source[srcExchange] && ( // pas de captage ou d'injection dans la config : on n'affiche pas les lignes
              <Fragment key={`${srcExchange}_lowerModule`}>
                <tr
                  key={`geoModule_${lowerModule?.name}`}
                  className={
                    (index === 0 || index === 1) && 'equipment-list-separator' // 0 = module captage, 1 = module injection
                  }
                >
                  <td>
                    {hasSubEquipment && (
                      <FontAwesomeIcon
                        className='equipment-list-chevron'
                        onClick={() => handleShowSubEquipment(lowerModuleRef)}
                        icon={
                          subEquipementList.includes(lowerModuleRef)
                            ? faChevronDown
                            : faChevronRight
                        }
                      />
                    )}
                  </td>
                  <td className='bold'>
                    <div className='equipment-list-warning-badge-cell'>
                      {lowerModule?.name ??
                        i18n._(
                          `geostorage.lowerModule.${srcExchange}.notFound`
                        )}
                      {lowerModuleError && (
                        <WarningBadge
                          tooltipContent={lowerModuleError}
                          tooltipId={'injectionUpperModule'}
                          portalContainer={document.body}
                          place='bottom'
                          iconSize='small'
                        />
                      )}
                    </div>
                  </td>
                  <td colSpan={2} className='bold'>
                    {getTableValue(
                      i18n,
                      prices?.moduleEquipmentTotal,
                      UNIT.EURO,
                      0
                    )}
                  </td>
                  <td className='bold'>
                    {getTableValue(
                      i18n,
                      prices?.manufacturingTotal,
                      UNIT.EURO,
                      0
                    )}
                  </td>
                  <td>
                    {getTableValue(
                      i18n,
                      prices?.hydraulicAndStructure,
                      UNIT.EURO,
                      0
                    )}
                  </td>
                  <td>
                    {getTableValue(
                      i18n,
                      prices?.factoryInsulation,
                      UNIT.EURO,
                      0
                    )}
                  </td>
                  <td>
                    {getTableValue(i18n, prices?.siteInsulation, UNIT.EURO, 0)}
                  </td>
                  <td>
                    {getTableValue(i18n, prices?.equipmentConfig, UNIT.EURO, 0)}
                  </td>
                  <td className='bold'>
                    {getTableValue(i18n, prices?.total, UNIT.EURO, 0)}
                  </td>
                </tr>
                {subEquipementList.includes(lowerModuleRef) && (
                  <SubEquipmentRow list={visibleLowerModuleEquipment} />
                )}
              </Fragment>
            )
          );
        }
      )}
      {/* Module haut injection */}
      {geostorage.settings.source.injection && ( // pas d'injection dans la config : on n'affiche pas la ligne
        <tr>
          <td></td>
          <td colSpan={3} className='bold'>
            <div className='equipment-list-warning-badge-cell'>
              {geostorage.upperModules.injection.name}
              {!isInjectionPriceComplete && (
                <WarningBadge
                  tooltipContent={i18n._(
                    'config.equipmentList.warning.incompletePrice'
                  )}
                  tooltipId={'injectionUpperModule'}
                  portalContainer={document.body}
                  place='bottom'
                  iconSize='small'
                />
              )}
            </div>
          </td>
          <td className='bold'>
            {getTableValue(
              i18n,
              geostorage.upperModules.injection.price,
              UNIT.EURO,
              0
            )}
          </td>
          <td>
            {getTableValue(
              i18n,
              geostorage.upperModules.injection.price,
              UNIT.EURO,
              0
            )}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td className='bold'>
            {getTableValue(
              i18n,
              geostorage.upperModules.injection.price,
              UNIT.EURO,
              0
            )}
          </td>
        </tr>
      )}
    </Fragment>
  );
};

export default GeostorageRows;
