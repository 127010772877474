import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useRef, useState } from 'react';
import { Form, InputGroup, Overlay, Tooltip } from 'react-bootstrap';
import { isNull } from '../../utils/data.utils';

// todo supprimer et utiliser ParamInput + ajv (JsonSchema)
const FormSelect = ({
  id,
  className,
  value,
  disabled,
  param,
  label,
  bottomText,
  topText,
  unit,
  infoIcon,
  infoTooltip,
  disabledOptions,
  showInfoIcon,
  onChange,
  onInfoClick
}) => {
    console.log('FormSelect');
    console.log('infoTooltip', infoTooltip);
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  //#endregion

  //#region [refs]
  const oldValue = useRef(value);
  const iconRef = useRef(null);
  //#endregion

  //#region [methods]
  const handleParamChange = async (evt) => {
    if (oldValue.current == evt.target.value) return;
    oldValue.current = evt.target.value;
    await onChange(
      isNaN(evt.target.value) ? evt.target.value : +evt.target.value
    );
  };
  //#endregion

  //#region [render]
  id = id ?? `input_${param.key}_` + new Date().getTime();
  const infoTooltipId = 'errTooltip_' + id;
  const inputClassName =
    !disabled && value && !isNull(param.default) && value != param.default
      ? 'form-input-changed'
      : '';
  return (
    <div className={className}>
      {label && (
        <label className='form-input-label'>
          {label}
          {param.required ? '*' : ''}
          {showInfoIcon && (
            <>
              <FontAwesomeIcon
                icon={infoIcon ?? 'circle-question'}
                className='info-icon'
                onClick={onInfoClick}
                onMouseOver={() => setShowInfoTooltip(true)}
                onMouseOut={() => setShowInfoTooltip(false)}
                ref={iconRef}
              />
              {infoTooltip && (
                <Overlay
                  target={iconRef.current}
                  show={showInfoTooltip}
                  placement='right'
                >
                  {(props) => (
                    <Tooltip
                      id={infoTooltipId}
                      className='form-input-info-tooltip'
                      {...props}
                    >
                      {infoTooltip}
                    </Tooltip>
                  )}
                </Overlay>
              )}
            </>
          )}
        </label>
      )}
      <div className='form-input-body'>
        {topText && (
          <p className='form-input-small-text form-input-top-text'>{topText}</p>
        )}
        <InputGroup className='form-input'>
          <Form.Select
            id={id}
            disabled={disabled}
            onChange={handleParamChange}
            value={value !== null || value !== undefined ? value : ''}
            className={`${inputClassName} ${
              disabled ? 'form-input-disabled' : ''
            } form-input ellipsis`}
          >
            {param.values.map((value, index) => (
              <option
                key={'input_' + param.key + '_value_' + index}
                value={value}
                disabled={disabledOptions?.includes(value)}
              >
                {param.translateValuesIds
                  ? i18n._(`${param.translateValuesIds[index]}`)
                  : value}
              </option>
            ))}
          </Form.Select>
          {unit && param.unit && (
            <InputGroup.Text id='form_input' className='form-input-unit'>
              {i18n._(`unit.${param.unit}`)}
            </InputGroup.Text>
          )}
        </InputGroup>
        {bottomText && (
          <span className='form-input-small-text'>{bottomText}</span>
        )}
      </div>
    </div>
  );
  //#endregion
};

export default FormSelect;
