import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { GEOLOGY_A_MAX, GEOLOGY_A_MIN, UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD } from '../../../../../server/constants';
import {
  BTES,
  SOIL,
  getSoilTempParams
} from '../../../../../server/models/design/geology.model';
import { GEOLOGY_SCHEMA } from '../../../../../server/validation/geology.validation';
import { fetchGeology, updateGeology, fetchUndisturbedSoilTemperature } from '../../../api/project.api';
import Bloc from '../../../components/Bloc/Bloc';
import ParamInput from '../../../components/ParamForm/ParamInput';

import { INPUT_TYPE } from '../../../constants';
import AjvContext from '../../../contexts/AjvContext';
import PopupContext from '../../../contexts/PopupContext';
import { IS_DEV } from '../../../utils/env.utils';
import { getDefaultValue } from '../../../utils/param.utils';
import { getAlphaError } from '../../../utils/project.utils';
import FormSelect from '../../../components/Form/FormSelect';
import './GeologyPage.css';

const GENERAL = Object.freeze({
  UndisturbedGroundTemperatureCalculationMethod: {
    key: 'UndisturbedGroundTemperatureCalculationMethod',
    type: 'string',
    values: Object.values(UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD),
    translateValuesIds: [
      'geology.UndisturbedGroundTemperatureCalculationMethod.WeatherFile',
      'geology.UndisturbedGroundTemperatureCalculationMethod.Manual'
    ],
    required: false,
    default: UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD.WEATHER_FILE
  },
});

const GeologyPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { ajv } = useContext(AjvContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [form, setForm] = useState();
  const [soilTemperature, setSoilTemperature] = useState();
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const undisturbedSoilTemperature = await fetchUndisturbedSoilTemperature(projectId);
        const form = await fetchGeology(projectId);

        setSoilTemperature(() => undisturbedSoilTemperature);

        if (!form.InitTrtChecked && form.InitUndisturbedSoilTemperatureCalculationMethod === UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD.WEATHER_FILE) {
          form.InitSoilTemperature10m = undisturbedSoilTemperature;
        }

        setForm(() => form);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [methods]
  const handleTRTChecked = (evt) => {
    const soilTempParams = getSoilTempParams(evt.target.checked);
    setForm((oldForm) => {
      oldForm.InitTrtChecked = evt.target.checked;
      if (!evt.target.checked) {
        oldForm.InitSoilTemperature10m =
          soilTempParams.InitSoilTemperature10m.default;
        oldForm.InitSoilTemperatureMean =
          soilTempParams.InitSoilTemperatureMean.default;
        oldForm.InitTrtProbeDepth = soilTempParams.InitTrtProbeDepth.default;

        if (oldForm.InitUndisturbedSoilTemperatureCalculationMethod === UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD.WEATHER_FILE) {
          oldForm.InitSoilTemperature10m = parseFloat(soilTemperature);
        } else {
          oldForm.InitSoilTemperature10m = soilTempParams.InitSoilTemperature10m.default;
        }
      } else {
        oldForm.InitSoilTemperature10m = soilTempParams.InitSoilTemperature10m.default;
      }
      return { ...oldForm };
    });
  };

  const handleUndisturbedGroundTemperatureCalculationMethodChange = (value) => {
    if (value === UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD.WEATHER_FILE) {
      setForm((oldForm) => {
        oldForm.InitUndisturbedSoilTemperatureCalculationMethod = value;
        oldForm.InitSoilTemperature10m = parseFloat(soilTemperature);
        return { ...oldForm };
      });
    } else if (value === UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD.MANUAL) {
      setForm((oldForm) => {
        oldForm.InitUndisturbedSoilTemperatureCalculationMethod = value;
        oldForm.InitSoilTemperature10m = soilTempParams.InitSoilTemperature10m.default;
        return { ...oldForm };
      });
    }
  };

  const handleInputChange = (key, value) => {
    setForm((form) => ({ ...form, [key]: value }));
  };

  const handleSubmitClick = async () => {
    try {
      await updateGeology(projectId, form);
      navigate(
        `/company/${companyId}/project/${projectId}/design/compute?type=opti`
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  if (!form) return null;
  const soilTempParams = getSoilTempParams(form.InitTrtChecked);
  const K = form.InitSoilThermalConductivity;
  const Cp = form.InitSoilVolumeHeatCapacity;
  const alphaError = getAlphaError(K, Cp)
    ? i18n._('geology.alphaError', {
      amin: GEOLOGY_A_MIN,
      amax: GEOLOGY_A_MAX
    })
    : null;
  const validate = ajv.compile(GEOLOGY_SCHEMA);
  const isFormValid = !alphaError && validate(form);
  console.log(form);
  if (IS_DEV && validate.errors) console.log(validate.errors);
  return (
    <div className='geology-page'>
      <Bloc title={i18n._('geology.trt')}>
        <Form.Check
          type='switch'
          label={i18n._('geology.InitTrtChecked')}
          checked={form.InitTrtChecked}
          onChange={handleTRTChecked}
          className={form.InitTrtChecked ? 'bold' : ''}
          name='trtChecked'
          id='trtChecked'
        />
        <Row>
          <Col>
            <FormSelect
              label={i18n._('geology.UndisturbedGroundTemperatureCalculationMethod')}
              value={form.InitUndisturbedSoilTemperatureCalculationMethod}
              param={GENERAL.UndisturbedGroundTemperatureCalculationMethod}
              bottomText=' '
              onChange={(value) => {
                handleUndisturbedGroundTemperatureCalculationMethodChange(value);
              }}
              showInfoIcon={true}
              infoIcon="fa-regular fa-circle-question"
              infoTooltip={i18n._('geology.UndisturbedGroundTemperatureCalculationMethod.tooltip')}
              disabled={form.InitTrtChecked}
            />
          </Col>
          <Col>
            <ParamInput
              label={i18n._('geology.InitSoilTemperature10m')}
              value={form.InitSoilTemperature10m}
              param={soilTempParams.InitSoilTemperature10m}
              onChange={(value) =>
                handleInputChange('InitSoilTemperature10m', value)
              }
              unit
              bottomText={getDefaultValue(
                i18n,
                soilTempParams.InitSoilTemperature10m
              )}
              type={INPUT_TYPE.NUMBER}
              disabled={form.InitTrtChecked || form.InitUndisturbedSoilTemperatureCalculationMethod !== UNDISTURBED_GROUND_TEMPERATURE_CALCULATION_METHOD.MANUAL}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ParamInput
              label={i18n._('geology.InitSoilTemperatureMean')}
              value={form.InitSoilTemperatureMean}
              param={soilTempParams.InitSoilTemperatureMean}
              onChange={(value) =>
                handleInputChange('InitSoilTemperatureMean', value)
              }
              unit
              type={INPUT_TYPE.NUMBER}
              disabled={!form.InitTrtChecked}
            />
          </Col>
          <Col>
            <ParamInput
              label={i18n._('geology.InitTrtProbeDepth')}
              value={form.InitTrtProbeDepth}
              param={soilTempParams.InitTrtProbeDepth}
              onChange={(value) =>
                handleInputChange('InitTrtProbeDepth', value)
              }
              unit
              disabled={!form.InitTrtChecked}
              type={INPUT_TYPE.NUMBER}
            />
          </Col>
        </Row>
      </Bloc>
      <Bloc title={i18n._('geology.soil')}>
        <Row>
          <Col>
            <ParamInput
              label={i18n._('geology.InitSoilThermalConductivity')}
              value={form.InitSoilThermalConductivity}
              param={SOIL.InitSoilThermalConductivity}
              onChange={(value) =>
                handleInputChange('InitSoilThermalConductivity', value)
              }
              unit
              bottomText={getDefaultValue(
                i18n,
                SOIL.InitSoilThermalConductivity
              )}
              type={INPUT_TYPE.NUMBER}
            />
          </Col>
          <Col>
            <ParamInput
              label={i18n._('geology.InitSoilVolumeHeatCapacity')}
              value={form.InitSoilVolumeHeatCapacity}
              param={SOIL.InitSoilVolumeHeatCapacity}
              onChange={(value) =>
                handleInputChange('InitSoilVolumeHeatCapacity', value)
              }
              unit
              bottomText={getDefaultValue(
                i18n,
                SOIL.InitSoilVolumeHeatCapacity
              )}
              type={INPUT_TYPE.NUMBER}
            />
          </Col>
        </Row>
        {alphaError && <Alert variant='danger'>{alphaError}</Alert>}
      </Bloc>
      <Bloc title={i18n._('geology.btes')}>
        <Row>
          <Col>
            <ParamInput
              label={i18n._('geology.InitBtesTemperatureMin')}
              value={form.InitBtesTemperatureMin}
              param={BTES.InitBtesTemperatureMin}
              onChange={(value) =>
                handleInputChange('InitBtesTemperatureMin', value)
              }
              unit
              bottomText={getDefaultValue(i18n, BTES.InitBtesTemperatureMin)}
              type={INPUT_TYPE.NUMBER}
            />
          </Col>
          <Col>
            <ParamInput
              label={i18n._('geology.InitBtesTemperatureMax')}
              value={form.InitBtesTemperatureMax}
              param={BTES.InitBtesTemperatureMax}
              onChange={(value) =>
                handleInputChange('InitBtesTemperatureMax', value)
              }
              unit
              bottomText={getDefaultValue(i18n, BTES.InitBtesTemperatureMax)}
              type={INPUT_TYPE.NUMBER}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ParamInput
              label={i18n._('geology.InitBtesTemperatureDelta')}
              value={form.InitBtesTemperatureDelta}
              param={BTES.InitBtesTemperatureDelta}
              onChange={(value) =>
                handleInputChange('InitBtesTemperatureDelta', value)
              }
              unit
              bottomText={getDefaultValue(i18n, BTES.InitBtesTemperatureDelta)}
              type={INPUT_TYPE.NUMBER}
            />
          </Col>
          <Col>
            <ParamInput
              label={i18n._('geology.YearsT2Final')}
              value={form.YearsT2Final}
              param={BTES.YearsT2Final}
              onChange={(value) => handleInputChange('YearsT2Final', value)}
              unit
              bottomText={getDefaultValue(i18n, BTES.YearsT2Final)}
              type={INPUT_TYPE.NUMBER}
            />
          </Col>
        </Row>
        <ParamInput
          label={i18n._('geology.InitBtesMaxWidth')}
          value={form.InitBtesMaxWidth}
          param={BTES.InitBtesMaxWidth}
          onChange={(value) => handleInputChange('InitBtesMaxWidth', value)}
          unit
          bottomText={getDefaultValue(i18n, BTES.InitBtesMaxWidth)}
          type={INPUT_TYPE.NUMBER}
        />
        <Row>
          <Col>
            <ParamInput
              label={i18n._('geology.InitBtesProbeResistance')}
              value={form.InitBtesProbeResistance}
              param={BTES.InitBtesProbeResistance}
              onChange={(value) =>
                handleInputChange('InitBtesProbeResistance', value)
              }
              unit
              bottomText={getDefaultValue(i18n, BTES.InitBtesProbeResistance)}
              type={INPUT_TYPE.NUMBER}
            />
          </Col>
          <Col>
            <ParamInput
              label={i18n._('geology.InitBtesProbeInternalDiameter')}
              value={form.InitBtesProbeInternalDiameter}
              param={BTES.InitBtesProbeInternalDiameter}
              onChange={(value) =>
                handleInputChange('InitBtesProbeInternalDiameter', value)
              }
              unit
              bottomText={getDefaultValue(
                i18n,
                BTES.InitBtesProbeInternalDiameter
              )}
              type={INPUT_TYPE.NUMBER}
            />
          </Col>
        </Row>
      </Bloc>
      <div className='bottom-page-btns'>
        <Button
          variant='primary'
          onClick={handleSubmitClick}
          disabled={!isFormValid}
        >
          {i18n._('save')}
        </Button>
      </div>
    </div>
  );
  //#endregion
};

export default GeologyPage;
