import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import {
  Menu,
  MenuItem,
  Sidebar as RSidebar,
  SubMenu
} from 'react-pro-sidebar';
import { Link, useLocation, useParams } from 'react-router-dom';
import ConfigsContext from '../../../../contexts/ConfigsContext';
import {
  areBuildingSettingsCompleted,
  areGeostorageSettingsCompleted,
  areHpsSettingsCompleted,
  areSelectedHpsCompleted,
  isBuildingHydraulicCompleted,
  isModularCompleted
} from '../../../../utils/config.utils';
import './Sidebar.css';

const Sidebar = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId, projectId, configId } = useParams();
  const location = useLocation();
  //#endregion

  //#region [contexts]
  const { savedConfig } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const isActive = (endpoint) => location.pathname.includes(endpoint);

  const handleDiagramClick = () => {
    const url = `${window.location.origin}/diagram/${configId}`;
    const newTabFeatures = 'toolbar=no,location=yes,menubar=no';
    window.open(url, '_blank', newTabFeatures);
  };
  //#endregion

  //#region [render]
  const designUrl = `/company/${companyId}/project/${projectId}/design/compute?type=opti`;
  return (
    <RSidebar>
      <Menu
        menuItemStyles={{
          button: ({ open, active }) => {
            if (open) return { fontWeight: '500' };
            if (active) {
              return {
                backgroundColor: '#f3f3f8',
                fontWeight: '600',
                color: 'var(--primary-color)'
              };
            }
          }
        }}
      >
        <h1>{i18n._('config.buildingDescription')}</h1>
        <MenuItem
          icon={<FontAwesomeIcon icon='circle-info' />}
          component={<Link to={`${configId}/general`} />}
          active={isActive('general')}
        >
          {i18n._('config.generalInformations')}
        </MenuItem>
        <SubMenu
          icon={<FontAwesomeIcon icon='building' />}
          label={i18n._('config.buildingServices')}
          disabled={!isModularCompleted(savedConfig.ConfigsSst[0])}
          defaultOpen={isActive('/services/')}
        >
          <MenuItem
            component={<Link to={`${configId}/services/settings`} />}
            active={isActive('services/settings')}
            disabled={!isModularCompleted(savedConfig.ConfigsSst[0])}
          >
            {i18n._('config.settings')}
          </MenuItem>
          <MenuItem
            component={<Link to={`${configId}/services/hydraulic`} />}
            active={isActive('services/hydraulic')}
            disabled={!areBuildingSettingsCompleted(savedConfig.ConfigsSst[0])}
          >
            {i18n._('config.hydraulicConfig')}
          </MenuItem>
        </SubMenu>
        <h1>{i18n._('config.thermalProduction')}</h1>
        <SubMenu
          icon={<FontAwesomeIcon icon='fan' />}
          label={i18n._('config.hps')}
          disabled={!isBuildingHydraulicCompleted(savedConfig.ConfigsSst[0])}
          defaultOpen={isActive('/heatpumps/')}
        >
          <MenuItem
            component={<Link to={`${configId}/heatpumps/settings`} />}
            active={isActive('heatpumps/settings')}
            disabled={!isBuildingHydraulicCompleted(savedConfig.ConfigsSst[0])}
          >
            {i18n._('config.settings')}
          </MenuItem>
          <MenuItem
            component={<Link to={`${configId}/heatpumps/selection`} />}
            active={isActive('heatpumps/selection')}
            disabled={!areHpsSettingsCompleted(savedConfig.ConfigsSst[0])}
          >
            {i18n._('config.selection')}
          </MenuItem>
          <MenuItem
            component={<Link to={`${configId}/heatpumps/hydraulic`} />}
            active={isActive('heatpumps/hydraulic')}
            disabled={!areSelectedHpsCompleted(savedConfig.ConfigsSst[0])}
          >
            {i18n._('config.hydraulicConfig')}
          </MenuItem>
        </SubMenu>
        <SubMenu
          icon={<FontAwesomeIcon icon='arrow-up-from-water-pump' />}
          label={i18n._('config.geostorage')}
          defaultOpen={isActive('/geostorage/')}
          disabled={!areSelectedHpsCompleted(savedConfig.ConfigsSst[0])}
        >
          <MenuItem
            component={<Link to={`${configId}/geostorage/settings`} />}
            active={isActive('geostorage/settings')}
            disabled={!areSelectedHpsCompleted(savedConfig.ConfigsSst[0])}
          >
            {i18n._('config.settings')}
          </MenuItem>
          <MenuItem
            component={<Link to={`${configId}/geostorage/hydraulic`} />}
            active={isActive('geostorage/hydraulic')}
            disabled={
              !areGeostorageSettingsCompleted(savedConfig.ConfigsSst[0])
            }
          >
            {i18n._('config.hydraulicConfig')}
          </MenuItem>
          <MenuItem
            component={<Link to={`${configId}/geostorage/calculations`} />}
            active={isActive('geostorage/calculations')}
            disabled={
              !areGeostorageSettingsCompleted(savedConfig.ConfigsSst[0])
            }
          >
            {i18n._('config.hydraulicCalculations')}
          </MenuItem>
        </SubMenu>
        <h1>{i18n._('config.hydraulicEquipment')}</h1>
        <SubMenu
          icon={<FontAwesomeIcon icon='water' />}
          label={i18n._('config.pumps')}
          disabled={!areSelectedHpsCompleted(savedConfig.ConfigsSst[0])}
          defaultOpen={isActive('/pumps/')}
        >
          <MenuItem
            component={<Link to={`${configId}/pumps/regimes`} />}
            active={isActive('pumps/regimes')}
            disabled={!areSelectedHpsCompleted(savedConfig.ConfigsSst[0])}
          >
            {i18n._('config.regimes')}
          </MenuItem>
          <MenuItem
            component={<Link to={`${configId}/pumps/heatingPlant`} />}
            active={isActive('pumps/heatingPlant')}
            disabled={!areSelectedHpsCompleted(savedConfig.ConfigsSst[0])}
          >
            {i18n._('config.heatingPlant')}
          </MenuItem>
          <MenuItem
            component={<Link to={`${configId}/pumps/calculations`} />}
            active={isActive('pumps/calculations')}
            disabled={
              !areGeostorageSettingsCompleted(savedConfig.ConfigsSst[0])
            }
          >
            {i18n._('config.hydraulicCalculations')}
          </MenuItem>
        </SubMenu>
        <hr />
        <h1>{i18n._('config.deliverable')}</h1>
        {savedConfig.IsModular && (
          <MenuItem
            icon={<FontAwesomeIcon icon='clipboard-list' />}
            component={<Link to={`${configId}/equipmentList`} />}
            active={isActive('equipmentList')}
            disabled={!areSelectedHpsCompleted(savedConfig.ConfigsSst[0])}
          >
            {i18n._('config.equipmentlist')}
          </MenuItem>
        )}
        <MenuItem
          icon={<FontAwesomeIcon icon='draw-polygon' />}
          component={<span onClick={handleDiagramClick} />}
          disabled={!areGeostorageSettingsCompleted(savedConfig.ConfigsSst[0])}
        >
          {i18n._('config.hydraulicDiagram')}
        </MenuItem>
        <div className='spacer'></div>
        <MenuItem
          icon={<FontAwesomeIcon icon='arrow-up-right-from-square' />}
          component={<Link to={`${designUrl}`} />}
        >
          {i18n._('app.design')}
        </MenuItem>
      </Menu>
    </RSidebar>
  );
  //#endregion
};

export default Sidebar;
