import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { MAX_HEATPUMPS } from '../../../../../../../../../server/constants';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import './CountCellRenderer.css';

const CountCellRenderer = ({
  data,
  value,
  selectedHpsCount,
  addHp,
  removeHp
}) => {
  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [render]
  const { needs } = config.ConfigsSst[0].Data.services;
  // on crée une copie de l'objet de la PAC, au cas où l'on ajoute 2 fois la même PAC
  const hpCopy = JSON.parse(JSON.stringify(data));
  return needs.some((need) => !data.capacity[need].error) ? (
    <div className='count-cell'>
      <FontAwesomeIcon
        icon='circle-minus'
        onClick={() => {
          if (value !== 0) removeHp(data);
        }}
        className={value === 0 ? 'disabled' : ''}
      />
      <span>{value}</span>
      <FontAwesomeIcon
        icon='circle-plus'
        onClick={() => {
          if (selectedHpsCount !== MAX_HEATPUMPS) addHp(hpCopy);
        }}
        className={selectedHpsCount < MAX_HEATPUMPS ? '' : 'disabled'}
      />
    </div>
  ) : null;
  //#endregion
};

export default CountCellRenderer;
