const isNull = (val) => val === null || val === undefined;

const isObjNullOrEmpty = (obj) => !obj || Object.keys(obj).length === 0;

const isArrNullOrEmpty = (arr) => !arr || arr.length === 0;

// arrondi un nombre à un nombre de décimales spécifié
const roundNumber = (num, precision = 2) => {
  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, precision)) /
    Math.pow(10, precision)
  );
};

// arrondi un nombre à l'entier supérieur
const roundUp = (num, precision = 2) => {
  if (isNaN(num) || isNull(num)) return num;
  const multiplier = Math.pow(10, precision);
  return Math.ceil(num * multiplier) / multiplier;
};

const containsNotNullValue = (obj, targetKey) => {
  if (typeof obj === 'object' && !isNull(obj)) {
    if (!isNull(obj[targetKey])) {
      return true;
    }
    for (const key in obj) {
      if (containsNotNullValue(obj[key], targetKey)) {
        return true;
      }
    }
  }
  return false;
};

function getStringifiedJSON(obj) {
  if (!obj) return null;
  if (typeof obj === 'string') return obj;
  return JSON.stringify(obj);
}

function getParsedJSON(str) {
  if (!str) return null;
  if (typeof str !== 'string') return str;
  return JSON.parse(str);
}

const boolToBinary = (bool) => (bool ? 1 : 0);

const getArraySum = (arr) => arr.reduce((acc, val) => acc + val, 0);

const getArrayPropertySum = (arr, prop) =>
  arr.reduce((acc, val) => acc + val[prop], 0);

const areAllElementsEqual = (array) =>
  array.every((element) => element === array[0]);

const arraysHaveSameElements = (arr1, arr2) =>
  arr1.length === arr2.length &&
  arr1.every((element1) => arr2.find((element2) => element2 === element1));

const getAverage = (arr) => {
  if (!arr || arr.length === 0) return 0;
  return arr.reduce((acc, curr) => acc + curr, 0) / arr.length;
};

const includes = (data, str) => {
  return !data ? false : data.includes(str);
};

module.exports = {
  roundNumber,
  roundUp,
  isNull,
  isObjNullOrEmpty,
  isArrNullOrEmpty,
  containsNotNullValue,
  getStringifiedJSON,
  getParsedJSON,
  containsNotNullValue,
  boolToBinary,
  getArraySum,
  getArrayPropertySum,
  areAllElementsEqual,
  arraysHaveSameElements,
  getAverage,
  includes
};
