import { useLingui } from '@lingui/react';
import React, { useContext, useRef, useState } from 'react';
import { HP_TYPE } from '../../../../../../../server/constants';
import { updateHpsSettings } from '../../../../../api/configSst.api';
import Bloc from '../../../../../components/Bloc/Bloc';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import { FORM_STATUS } from '../../../../../constants';
import ConfigsContext from '../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../contexts/PopupContext';
import { isArrNullOrEmpty } from '../../../../../utils/data.utils';
import { checkForHpMissingCapacitiesError } from '../../../../../utils/heatpump.utils';
import ConfigFormPage from '../../../components/ConfigFormPage/ConfigFormPage';
import './SettingsPage.css';
import HpaSection from './sections/HpaSection/HpaSection';
import HpgSection from './sections/HpgSection';
import HpsTypesSection from './sections/HpsTypesSection';
import RegimesSection from './sections/RegimesSection/RegimesSection';

const SettingsPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config, setConfig, refreshConfig, modules } =
    useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [formStatus, setFormStatus] = useState(() => {
    const { services, heatpumps } = config.ConfigsSst[0].Data;
    if (isArrNullOrEmpty(heatpumps.list)) return FORM_STATUS.ORIGIN;
    return checkForHpMissingCapacitiesError(heatpumps.list, services.needs)
      ? FORM_STATUS.DIRTY
      : FORM_STATUS.ORIGIN;
  });
  const [nbErrors, setNbErrors] = useState(0);
  //#endregion

  //#region [refs]
  const hpRegimesNbErrors = useRef({
    [HP_TYPE.AERO]: 0,
    [HP_TYPE.GEO]: 0
  });
  //#endregion

  //#region [methods]
  const addError = () => setNbErrors((prevErrors) => prevErrors + 1);

  const removeErrors = (value) =>
    setNbErrors((prevErrors) => prevErrors - value);

  const save = async () => {
    try {
      await updateHpsSettings(config.IsModular, config.ConfigsSst[0], modules);
      await refreshConfig();
    } catch (err) {
      throw err;
    }
  };

  const handleFormChange = (conf) => {
    setConfig(() => ({ ...conf }));
    setFormStatus(() => FORM_STATUS.DIRTY);
  };

  const handleRemoveTypesError = (hpType) => {
    removeErrors(hpRegimesNbErrors.current[hpType]);
    hpRegimesNbErrors.current[hpType] = 0;
  };

  const handleAddRegimeError = (hpType) => {
    if (hpType) hpRegimesNbErrors.current[hpType]++;
    addError();
  };

  const handleRemoveRegimeError = (hpType) => {
    if (hpType) hpRegimesNbErrors.current[hpType]--;
    removeErrors(1);
  };

  const handleSaveClick = async () => {
    try {
      setFormStatus(() => FORM_STATUS.SAVING);
      await save();
      setFormStatus(() => FORM_STATUS.SAVED);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
      setFormStatus(() => FORM_STATUS.DIRTY);
    }
  };
  //#endregion

  //#region [render]
  const { types } = config.ConfigsSst[0].Data.heatpumps.settings;
  const formErrors = types.length === 0 || nbErrors > 0;
  return (
    <ConfigFormPage formStatus={formStatus} formErrors={formErrors} save={save}>
      <Bloc title={i18n._('config.hps.settings.title')}>
        <HpsTypesSection
          onFormChange={handleFormChange}
          removeErrors={handleRemoveTypesError}
        />
        {types.includes(HP_TYPE.GEO) && (
          <HpgSection
            onFormChange={handleFormChange}
            addError={addError}
            removeErrors={removeErrors}
          />
        )}
        {types.includes(HP_TYPE.AERO) && (
          <HpaSection
            onFormChange={handleFormChange}
            addError={addError}
            removeErrors={removeErrors}
          />
        )}
        {types.length >= 1 && (
          <RegimesSection
            onFormChange={handleFormChange}
            addError={handleAddRegimeError}
            removeError={handleRemoveRegimeError}
          />
        )}
      </Bloc>
      <SaveButton
        disabled={formErrors}
        formStatus={formStatus}
        onClick={handleSaveClick}
      />
    </ConfigFormPage>
  );
  //#endregion
};

export default SettingsPage;
