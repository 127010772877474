import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Link, NavLink, useParams } from 'react-router-dom';
import { GENERAL } from '../../../../../../server/models/design/general.model';
import { fetchImageFileAsObjectURL } from '../../../../api/imageFile.api';
import Card from '../../../../components/Card/Card';
import PopupContext from '../../../../contexts/PopupContext';
import defaultImg from '../../../../image/default_project.png';
import { formatValueWithUnit } from '../../../../utils/data.utils';
import './ProjectHeader.css';

const ProjectHeader = ({ project }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [image, setImage] = useState();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        const image = project.ImageFileID
          ? await fetchImageFileAsObjectURL(project.ImageFileID)
          : defaultImg;
        setImage(image);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [project.ImageFileID]);
  //#endregion

  //#region [render]
  const baseUrl = `/company/${companyId}/project/${projectId}/design`;
  const configUrl = `/company/${companyId}/project/${projectId}/configs`;
  return (
    <Card className='project-header'>
      {image ? <img src={image} alt='projectImg' /> : null}
      <div>
        <p className='project-header-name ellipsis' title={project.AhsName}>
          {project.AhsName}
          <Link to={`${baseUrl}/general`}>
            <FontAwesomeIcon icon='pen-to-square' />
          </Link>
        </p>
        <div className='project-header-badges'>
          <Badge>
            {formatValueWithUnit(
              i18n,
              project.InitBuildingSurface,
              GENERAL.InitBuildingSurface.unit
            )}
          </Badge>
          <Badge>{i18n._(`usage.${project.AhsMainUsage}`)}</Badge>
        </div>
      </div>
      <nav className='nav-pills'>
        <NavLink to={`${baseUrl}/compute?type=opti`} className='nav-link'>
          {i18n._(`page.compute`)}
        </NavLink>
        <NavLink to={`${baseUrl}/results`} className='nav-link'>
          {i18n._(`page.results`)}
        </NavLink>
        <NavLink to={`${configUrl}`} className='nav-link'>
          {i18n._(`app.config`)}
          <FontAwesomeIcon icon='arrow-up-right-from-square' className='ms-2' />
        </NavLink>
      </nav>
    </Card>
  );
  //#endregion
};

export default ProjectHeader;
