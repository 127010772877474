const {
  EXCHANGER_KEY_WORD,
  HEAT_TRANSFER_FLUID,
  MODULE_TYPE,
  NEED,
  DHW_TYPE,
  MODULE_PRIMARY_HE,
  MODULE_SECONDARY_HE,
  UNIT
} = require('../../../../constants');
const { getDefaultValues } = require('../../../../utils/param.utils');

const getPrimaryModuleParams = (services) => {
  return {
    Vx00: {
      [NEED.HEAT]: {
        value: MODULE_PRIMARY_HE.V100,
        default: !!services[NEED.HEAT]
      },
      [NEED.COLD]: {
        value: MODULE_PRIMARY_HE.V200,
        default: !services[NEED.COLD]?.combinedHotColdWaterTank
      },
      [NEED.DHW]: {
        value: MODULE_PRIMARY_HE.V300,
        default: !!services[NEED.DHW]
      }
    },
    Sx00: {
      [NEED.HEAT]: {
        value: MODULE_PRIMARY_HE.S100,
        default: !!services[NEED.HEAT]
      },
      [NEED.COLD]: {
        value: MODULE_PRIMARY_HE.S200,
        default: !services[NEED.COLD]?.combinedHotColdWaterTank
      },
      [NEED.DHW]: {
        value: MODULE_PRIMARY_HE.S300,
        default: !!services[NEED.DHW]
      }
    },
    Prx00a: {
      [NEED.HEAT]: {
        value: MODULE_PRIMARY_HE.PR100A,
        default: !!services[NEED.HEAT]
      },
      [NEED.COLD]: {
        value: MODULE_PRIMARY_HE.PR200A,
        default: !!services[NEED.COLD]
      },
      [NEED.DHW]: {
        value: MODULE_PRIMARY_HE.PR300A,
        default: !!services[NEED.DHW]
      }
    },
    Prx00s: {
      [NEED.HEAT]: {
        value: MODULE_PRIMARY_HE.PR100S,
        default: !!services[NEED.HEAT]
      },
      [NEED.COLD]: {
        value: MODULE_PRIMARY_HE.PR200S,
        default: !!services[NEED.COLD]
      },
      [NEED.DHW]: {
        value: MODULE_PRIMARY_HE.PR300S,
        default: !!services[NEED.DHW]
      }
    },
    Etx00: {
      [NEED.HEAT]: {
        value: MODULE_PRIMARY_HE.ET100,
        default: !!services[NEED.HEAT]
      },
      [NEED.COLD]: {
        value: MODULE_PRIMARY_HE.ET200,
        default: !!services[NEED.COLD]
      },
      [NEED.DHW]: {
        value: MODULE_PRIMARY_HE.ET300,
        default: !!services[NEED.DHW]
      }
    },
    Prx11: {
      [NEED.HEAT]: {
        value: MODULE_PRIMARY_HE.PR111,
        default: !!services[NEED.HEAT]?.exchanger
      },
      [NEED.COLD]: {
        value: MODULE_PRIMARY_HE.PR211,
        default: !!services[NEED.COLD]?.exchanger
      },
      [NEED.DHW]: {
        value: MODULE_PRIMARY_HE.PR311,
        default:
          services[NEED.DHW]?.dhwType === DHW_TYPE.SEMI ||
          services[NEED.DHW]?.dhwType === DHW_TYPE.STORAGE
      }
    },
    Prx12: {
      [NEED.HEAT]: {
        value: MODULE_PRIMARY_HE.PR112,
        default: !!services[NEED.HEAT]?.exchanger
      },
      [NEED.COLD]: {
        value: MODULE_PRIMARY_HE.PR212,
        default: !!services[NEED.COLD]?.exchanger
      },
      [NEED.DHW]: {
        value: MODULE_PRIMARY_HE.PR312,
        default:
          services[NEED.DHW]?.dhwType === DHW_TYPE.SEMI ||
          services[NEED.DHW]?.dhwType === DHW_TYPE.STORAGE
      }
    },
    Prx13: {
      [NEED.HEAT]: {
        value: MODULE_PRIMARY_HE.PR113,
        default: !!services[NEED.HEAT]?.exchanger
      },
      [NEED.COLD]: {
        value: MODULE_PRIMARY_HE.PR213,
        default: !!services[NEED.COLD]?.exchanger
      },
      [NEED.DHW]: {
        value: MODULE_PRIMARY_HE.PR313,
        default:
          services[NEED.DHW]?.dhwType === DHW_TYPE.SEMI ||
          services[NEED.DHW]?.dhwType === DHW_TYPE.STORAGE
      }
    },
    Prx14: {
      [NEED.HEAT]: {
        value: MODULE_PRIMARY_HE.PR114,
        default: !!services[NEED.HEAT]?.exchanger
      },
      [NEED.COLD]: {
        value: MODULE_PRIMARY_HE.PR214,
        default: !!services[NEED.COLD]?.exchanger
      },
      [NEED.DHW]: {
        value: MODULE_PRIMARY_HE.PR314,
        default:
          services[NEED.DHW]?.dhwType === DHW_TYPE.SEMI ||
          services[NEED.DHW]?.dhwType === DHW_TYPE.STORAGE
      }
    }
  };
};

const getSecondaryModuleParams = (services) => {
  return {
    Pux00: {
      [NEED.HEAT]: {
        value: MODULE_SECONDARY_HE.PU100,
        default: !!services[NEED.HEAT]?.exchanger
      },
      [NEED.COLD]: {
        value: MODULE_SECONDARY_HE.PU200,
        default: !!services[NEED.COLD]?.exchanger
      },
      [NEED.DHW]: {
        value: MODULE_SECONDARY_HE.PU300,
        default:
          services[NEED.DHW]?.dhwType === DHW_TYPE.SEMI ||
          services[NEED.DHW]?.dhwType === DHW_TYPE.STORAGE
      }
    },
    Pux20: {
      [NEED.HEAT]: {
        value: MODULE_SECONDARY_HE.PU120,
        default: services[NEED.HEAT]?.secondaryControl
      },
      [NEED.COLD]: {
        value: MODULE_SECONDARY_HE.PU220,
        default: !!services[NEED.COLD]?.secondaryControl
      }
    },
    Prx21: {
      [NEED.HEAT]: {
        value: MODULE_SECONDARY_HE.PR121,
        default: !!services[NEED.HEAT]?.secondaryControl
      },
      [NEED.COLD]: {
        value: MODULE_SECONDARY_HE.PR221,
        default:
          !services[NEED.COLD]?.combinedHotColdWaterTank &&
          services[NEED.COLD]?.secondaryControl
      },
      [NEED.DHW]: {
        value: MODULE_SECONDARY_HE.PR321,
        default: !!services[NEED.DHW]
      }
    },
    Prx20: {
      [NEED.HEAT]: {
        value: MODULE_SECONDARY_HE.PR120,
        default: !!services[NEED.HEAT]?.secondaryControl
      },
      [NEED.COLD]: {
        value: MODULE_SECONDARY_HE.PR220,
        default:
          !services[NEED.COLD]?.combinedHotColdWaterTank &&
          services[NEED.COLD]?.secondaryControl
      }
    },
    Vmx20: {
      [NEED.HEAT]: {
        value: MODULE_SECONDARY_HE.VM120,
        default: false
      }
    }
  };
};

const getDefaultPrimaryModule = (services, need) => {
  const params = getPrimaryModuleParams(services);
  return Object.values(params).reduce(
    (acc, equipmentPerNeed) => {
      if (equipmentPerNeed[need]) {
        acc[MODULE_TYPE.PRIMARY][equipmentPerNeed[need].value] =
          equipmentPerNeed[need].default;
      }
      return acc;
    },
    { [MODULE_TYPE.PRIMARY]: {} }
  );
};

const getDefaultSecondaryModule = (services, need) => {
  const params = getSecondaryModuleParams(services);
  return Object.values(params).reduce(
    (acc, equipmentPerNeed) => {
      if (equipmentPerNeed[need]) {
        acc[MODULE_TYPE.SECONDARY][equipmentPerNeed[need].value] =
          equipmentPerNeed[need].default;
      }
      return acc;
    },
    { [MODULE_TYPE.SECONDARY]: {} }
  );
};

const getDefaultHydraulicEquipment = (services, need) => {
  return {
    ...getDefaultPrimaryModule(services, need),
    ...getDefaultSecondaryModule(services, need)
  };
};

// MODULES HAUTS
const getUpperModulesParams = (services) => {
  // on additionne les puissances max du chaud et de l'ecs
  let condSizingValue = services.needs
    .filter((need) => need !== NEED.COLD)
    .reduce((acc, need) => {
      return acc + services[need].pMax;
    }, 0);
  return {
    [EXCHANGER_KEY_WORD.EVAP]: {
      sizingCapacity: {
        key: 'sizingCapacity',
        default: services?.cold?.pMax ?? null,
        unit: UNIT.KILOWATT,
        type: 'number',
        min: 0,
        max: 2140,
        required: true
      },
      fluid: {
        key: 'fluid',
        default: HEAT_TRANSFER_FLUID.GLYCOL
      },
      nominalDiameter: { key: 'nominalDiameter' }
    },
    [EXCHANGER_KEY_WORD.COND]: {
      sizingCapacity: {
        key: 'sizingCapacity',
        default: condSizingValue === 0 ? null : condSizingValue,
        unit: UNIT.KILOWATT,
        type: 'number',
        min: 0,
        max: 3021,
        required: true
      },
      fluid: { key: 'fluid', default: HEAT_TRANSFER_FLUID.WATER },
      nominalDiameter: { key: 'nominalDiameter' }
    }
  };
};

const getDefaultUpperModules = (services) => {
  const params = getUpperModulesParams(services);
  return {
    [EXCHANGER_KEY_WORD.EVAP]: {
      ...getDefaultValues(params[EXCHANGER_KEY_WORD.EVAP])
    },
    [EXCHANGER_KEY_WORD.COND]: {
      ...getDefaultValues(params[EXCHANGER_KEY_WORD.COND])
    }
  };
};

module.exports = {
  getPrimaryModuleParams,
  getSecondaryModuleParams,
  getDefaultHydraulicEquipment,
  getUpperModulesParams,
  getDefaultUpperModules
};
